<template>
  <div class="request-list-div">
    <v-card class="px-5 py-3">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
        >
          <h4 class="dmsCategoryTitle1">
            DOKUMEN LEGALITAS
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refresh"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h4>
          <h4 class="dmsCategoryTitle2">
            Peminjaman Dokumen
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refresh"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h4>
        </v-col>
        <v-col cols="12">
          <div class="request-list-toolbar-1">
            <v-toolbar
              class="request-list-toolbar-div"
              flat
              style="height: 60px; border-radius: 10px 10px 0 0"
            >
              <v-toolbar-title style="color:indigo;">
                Peminjaman Dokumen</v-toolbar-title
              >
              <v-divider class="mx-6" inset vertical></v-divider>
              <div v-if="permission.create_perm == 1" style="margin-left: 10px">
                <v-btn
                  @click="borrow"
                  rounded
                  outlined
                  small
                  elevation="1"
                  color="indigo"
                  class="indigo--text"
                  style="font-size:12px;"
                >
                  Pinjam
                </v-btn>
              </div>
            </v-toolbar>
          </div>
          <div class="request-list-toolbar-1">
            <v-toolbar
              class="request-list-toolbar-div"
              flat
              style="height: 40px;"
            >
              <div
                v-if="permission.update_perm == 1"
                style="width: 120px;margin-right:20px;"
              >
                <v-select
                  :disabled="selectedData.length === 0"
                  label="Action"
                  style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                  v-model="actionValue"
                  :items="[
                    { id: 0, name: '' },
                    { id: 2, name: 'Return' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                  @change="action"
                ></v-select>
              </div>
              <div style="width: 120px">
                <v-select
                  label="Status"
                  style="
                        position: relative;
                        top: 15px;
                        margin-right: 20px;
                        font-size: 12px;
                      "
                  v-model="paramAPI.status"
                  :items="[
                    { id: '', name: 'All' },
                    { id: 0, name: 'Need Approval' },
                    { id: 2, name: 'Loaned' },
                    { id: 8, name: 'Returned' },
                    { id: 9, name: 'Granted Download' },
                    { id: 10, name: 'Approved' },
                    { id: -1, name: 'Reject' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                ></v-select>
              </div>
              <v-text-field
                v-model="paramAPI.keyword"
                label="Cari request"
                type="search"
                append-icon="mdi-magnify"
                outlined
                dense
                @keyup.enter="searchEnter"
                style="position: relative; top: 15px;"
              >
              </v-text-field>
            </v-toolbar>
          </div>
          <div class="request-list-toolbar-2">
            <v-expansion-panels flat accordion>
              <v-expansion-panel v-for="(item, i) in 1" :key="i" flat>
                <v-expansion-panel-header
                  class="d-flex flex-column align-items-start"
                  style="padding:0; margin:0;"
                >
                  <v-toolbar
                    class="request-list-toolbar-2"
                    flat
                    style="height: 60px; width:100%;"
                  >
                    <div
                      v-if="permission.update_perm == 1"
                      style="width: 120px;margin-right:20px;"
                    >
                      <v-select
                        @click.stop
                        :disabled="selectedData.length === 0"
                        label="Action"
                        style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                        v-model="actionValue"
                        :items="[
                          { id: 0, name: '' },
                          { id: 2, name: 'Return' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        @change="action"
                      ></v-select>
                    </div>
                    <div
                      v-if="permission.create_perm == 1"
                      style="display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        @click="borrow"
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;"
                      >
                        Pinjam
                      </v-btn>
                    </div>
                  </v-toolbar>
                  <v-toolbar
                    flat
                    style="height: 60px;border-bottom: 1px solid #e0e0e0; width:100%;"
                  >
                    <v-text-field
                      @click.stop
                      v-model="paramAPI.keyword"
                      label="Cari kategori"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                      @keyup.enter="searchEnter"
                      style="position: relative; top: 15px;"
                    >
                    </v-text-field>
                  </v-toolbar>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="expansionPanelContent">
                  <v-toolbar flat style="height: 60px; width:100%;">
                    <div style="width: 120px">
                      <v-select
                        label="Status"
                        style="
                        position: relative;
                        top: 15px;
                        margin-right: 20px;
                        font-size: 12px;
                      "
                        v-model="paramAPI.status"
                        :items="[
                          { id: '', name: 'All' },
                          { id: 0, name: 'Need Approval' },
                          { id: 2, name: 'Loaned' },
                          { id: 8, name: 'Returned' },
                          { id: 9, name: 'Granted Download' },
                          { id: 10, name: 'Approved' },
                          { id: -1, name: 'Reject' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                      >
                      </v-select>
                    </div>
                  </v-toolbar>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>

        <v-col cols="12">
          <div style=" overflow:auto;">
            <v-data-table
              mobile-breakpoint="0"
              v-model="selectedData"
              class="elevation-1"
              height="55vh"
              style="cursor: pointer; min-width:1200px;"
              :headers="headers"
              :items="result"
              :items-per-page="10"
              :loading="loading"
              @click:row="rowClick"
              :show-select="permission.update_perm == 1"
              item-key="id"
              :single-select="true"
              fixed-header
            >
              <template v-slot:[`item.loan_start_date`]="{ item }">
                <div
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  {{ convertDate(item.loan_start_date) }}
                </div>
              </template>
              <template v-slot:[`item.loan_end_date`]="{ item }">
                <!-- <div style="padding: 0; display: flex; flex-direction: row">
                    {{ convertDate(item.loan_end_date) }}
                  </div> -->
                <td class="arch-expDate">
                  <div class="arch-exp-date">
                    <div style="width: 120px; padding: 0 5px">
                      <p style="margin: 0; font-size:12px;">
                        {{ convertDate(item.loan_end_date) }}
                      </p>
                    </div>
                    <div v-if="item.status !== null" class="arch-exp-date-div1">
                      <div
                        class="arch-exp-date-notif"
                        v-if="countdown(item.loan_end_date) <= '7'"
                      >
                        <div
                          v-if="item.status.id === 2"
                          class="exp-date-notif-div"
                        >
                          <v-chip
                            class="exp-date-notif-chip1"
                            v-if="countdown(item.loan_end_date) > '0'"
                            small
                            color="orange"
                            text-color="white"
                          >
                            Done in
                            {{ countdown(item.loan_end_date) }} days
                          </v-chip>
                          <v-chip
                            class="exp-date-notif-chip2"
                            v-else-if="countdown(item.loan_end_date) < '0'"
                            small
                            color="red"
                            text-color="white"
                          >
                            <p class="date-notif-chip2-p">
                              exceeded
                            </p>
                          </v-chip>
                          <v-chip
                            class="exp-date-notif-chip2"
                            v-else
                            small
                            color="pink"
                            text-color="white"
                          >
                            <p class="date-notif-chip2-p">Done</p>
                          </v-chip>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  {{ convertDate2(item.created_at) }}
                </div>
              </template>
              <template v-slot:[`item.pic_name`]="{ item }">
                <div
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  {{ item.employee.name }}
                </div>
              </template>
              <template v-slot:[`item.doc`]="{ item }">
                <div
                  v-if="item.doc.document_type_id === 2"
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  SCP
                </div>
                <div
                  v-if="item.doc.document_type_id === 1"
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  HCP
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <div v-if="item.status !== null">
                  <div
                    v-if="item.status.id === 9"
                    style="padding: 0; display: flex; flex-direction: row"
                  >
                    <p
                      style="margin:0; width:90px; position:relative; font-size:14px;"
                    >
                      <span
                        class="docSpanDownload green--text"
                        @click.stop="downloadSoftCopy(item)"
                        >Download
                        <v-icon color="green" small>mdi-download</v-icon></span
                      >
                    </p>
                  </div>
                  <div
                    v-else
                    :style="
                      `padding: 0; display: flex; flex-direction: row;color:${status(
                        item.status
                      )}`
                    "
                  >
                    {{ item.status.name }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.loan_number`]="{ item }">
                <div
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  {{ item.loan_number }}
                </div>
              </template>
              <template v-slot:[`item.doc.doc_name`]="{ item }">
                <div
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  {{ item.doc.doc_name }}
                </div>
              </template>
              <template v-slot:[`item.doc.doc_number`]="{ item }">
                <div
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  {{ item.doc.doc_number }}
                </div>
              </template>
              <template v-slot:[`item.doc.name`]="{ item }">
                <div
                  style="padding: 0; display: flex; flex-direction: row; font-size:12px;"
                >
                  {{ item.doc.name }}
                </div>
              </template>
            </v-data-table>
          </div>
        </v-col>

        <div class="pr-items-add-new-line">
          <ReturnDocument
            :param="{
              items: itemEdit
            }"
            @loadPage="initDataTable"
            :permission="permission"
          />
        </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapMutations, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  props: ['isUser', 'permission'],
  components: {
    ReturnDocument: () => import('../archive-manage/Return')
  },
  data: () => ({
    eSanqua: buildType.apiURL('esanqua'),
    download: buildType.apiURL('download'),
    isAdmin: false,
    paramAPI: {
      keyword: '',
      offset: 0,
      limit: 1000,
      status: ''
    },
    actionValue: 1,
    headers: [
      {
        text: 'No. Peminjaman',
        value: 'loan_number',
        align: 'left',
        sortable: true
      },
      {
        text: 'PIC',
        value: 'pic_name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Nama Dokumen',
        value: 'doc.name',
        align: 'left',
        sortable: true
      },
      {
        text: 'No. Dokumen',
        value: 'doc.doc_no',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tipe',
        value: 'doc',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Pinjam',
        value: 'loan_start_date',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Selesai',
        value: 'loan_end_date',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Dibuat',
        value: 'created_at',
        align: 'left',
        sortable: true
        // width: '3%'
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: true
      }
    ],
    result: [],
    selectedData: [],
    loading: false,
    itemEdit: null
  }),
  watch: {
    'paramAPI.status'() {
      this.initDataTable()
    }
  },
  created() {
    // if (this.isAdmin) {
    this.initDataTable()
    // }
  },
  methods: {
    ...mapMutations(['setLoaningDocId', 'setLoaningDocumentName']),
    ...mapActions(['dropdownDocStatus']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    refresh() {
      this.initDataTable()
    },
    action(event) {
      switch (event) {
        case 1:
          break
        case 2:
          if (this.selectedData[0].status !== null) {
            if (this.selectedData[0].status.id === 2) {
              this.dropdownDocStatus()
              this.itemEdit = {
                id: this.selectedData[0].doc.id,
                loaned_id: this.selectedData[0].id
              }
              setTimeout(() => {
                document.getElementById('archiveReturn').click()
              }, 500)
            } else {
              this.showMsgDialog('warning', 'Document already returned', false)
              setTimeout(() => {
                this.selectedData = []
                this.actionValue = 0
              }, 200)
            }
          } else {
            this.showMsgDialog(
              'warning',
              "Can't proceed this action, Invalid document status",
              false
            )
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = 0
            }, 200)
          }
          break
      }
    },
    searchEnter() {
      this.initDataTable()
    },
    initDataTable() {
      this.actionValue = 0
      this.selectedData = []
      axios
        .get(
          `${this.eSanqua}archive_document/loan/list?keyword=${this.paramAPI.keyword}&offset=&limit=&is_archived=&loan_start_date=&loan_end_date=&status=${this.paramAPI.status}`
        )
        .then(res => {
          console.log(res)
          this.result = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    rowClick(pValue) {
      if (this.permission.read_perm === 1) {
        this.setLoaningDocId([pValue.id, pValue.doc.name])
        this.$router.push('/archive-management/loaning-list/detail')
      }
    },
    borrow() {
      this.setLoaningDocumentName(['', null, null])
      this.$router.push('/archive-management/loaning-list/form-loan')
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)

        // switch (month) {
        //   case '01':
        //     month = 'JAN'
        //     break
        //   case '02':
        //     month = 'FEB'
        //     break
        //   case '03':
        //     month = 'MAR'
        //     break
        //   case '04':
        //     month = 'APR'
        //     break
        //   case '05':
        //     month = 'MAY'
        //     break
        //   case '06':
        //     month = 'JUN'
        //     break
        //   case '07':
        //     month = 'JUL'
        //     break
        //   case '08':
        //     month = 'AGS'
        //     break
        //   case '09':
        //     month = 'SEP'
        //     break
        //   case '10':
        //     month = 'OKT'
        //     break
        //   case '11':
        //     month = 'NOV'
        //     break
        //   case '12':
        //     month = 'DES'
        //     break
        // }

        // return day + ' ' + month + ' ' + year
        return (day + '-' + month + '-' + year).replace(/-/g, '/')
      }
    },
    convertDate2(raw) {
      const date = new Date(raw)
      // const xDate = raw.slice(0, raw.lastIndexOf('T'))

      // const year = xDate.slice(0, xDate.indexOf('-'))
      // const month = xDate.slice(xDate.indexOf('-') + 1, xDate.lastIndexOf('-'))
      // const day = xDate.slice(xDate.lastIndexOf('-') + 1)
      // const newDate = day + '/' + month + '/' + year
      // console.log(date.toLocaleTimeString())

      return date.toLocaleString('id')
    },

    countdown(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        const z = new Date(year + '/' + month + '/' + day)

        const a = new Date()
        const n = a.toLocaleDateString()
        const day2 = n.slice(0, n.indexOf('/'))
        const month2 = n.slice(n.indexOf('/') + 1, n.lastIndexOf('/'))
        const year2 = n.slice(n.lastIndexOf('/') + 1)
        const x = new Date(year2 + '/' + month2 + '/' + day2)

        const diffInTime = z.getTime() - x.getTime()
        const q = diffInTime / (1000 * 3600 * 24)

        return q
      }
    },

    downloadSoftCopy(pValue) {
      console.log(pValue)
      axios({
        url: `${this.download}esanqua/archive_document/edocument/${pValue.edoc_token}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          console.log(res)
          if (res.status === 204) {
            // } else {
            return this.showMsgDialog(
              'warning',
              'Link kadaluarsa, mohon lakukan permintaan ulang',
              false
            )
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${pValue.doc.doc_no}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    },
    status(raw) {
      // if (raw.id === 9) {
      //   return 'green'
      // }
      // if (raw.id === 0) {
      //   return 'red'
      // }
      if (raw.id === 8 || raw.id === 9 || raw.id === 10) {
        return 'green'
      }
      if (raw.id === 9) {
        return 'black'
      }
      if (raw.id === 2) {
        return 'orange'
      }
      if (raw.id === -1 || raw.id === 0 || raw.id === 7) {
        return 'red'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.docSpanDownload {
  border: 1px solid rgba(0, 0, 0, 0.568);
  font-weight: 500;
  border-radius: 3px;
  padding: 1px 2px;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.384);

  &:hover {
    font-weight: bold;
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.384);
  }
}
#requestList {
  margin-bottom: 10px;
  .request-list-div {
    padding: 40px 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .request-list-toolbar-1 {
      .request-list-toolbar-div {
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .request-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-div1 {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      height: 40px;
      .arch-exp-date-notif {
        display: flex;
        height: 100%;
        // justify-content: center;
        align-items: center;
        // overflow: hidden;
        // width: 120px;
        // position: absolute;
        // left: 0;
        .exp-date-notif-div {
          display: flex;

          .exp-date-notif-chip1 {
            width: 95px;
            font-size: 10px;
          }
          .exp-date-notif-chip2 {
            font-size: 10px;
            .date-notif-chip2-p {
              width: 70px;
              margin: 0;
              margin: auto;
              text-align: center;
              // padding: 0 10px !important;
            }
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}

#expansionPanelContent > * {
  padding: 0px;
}

.dmsCategoryTitle1 {
  font-weight: bold;
  margin: auto;
  color: indigo;
}
.dmsCategoryTitle2 {
  display: none;
}
@media (max-width: 768px) {
  #requestList {
    .request-list-div {
      .request-list-toolbar-1 {
        .request-list-toolbar-div {
          display: none;
        }
      }
      .request-list-toolbar-2 {
        display: block;
      }
    }
  }

  .dmsCategoryTitle1 {
    display: none;
  }
  .dmsCategoryTitle2 {
    display: block;
    font-weight: bold;
    margin: 0;
    color: indigo;
  }
}
</style>
